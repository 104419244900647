import { detectConcordiumProvider } from "@concordium/browser-wallet-api-helpers";
import {
  getPastDate,
  MIN_DATE,
  Web3StatementBuilder,
} from "@concordium/web-sdk";
import { useEffect, useState } from "react";
import { useAxios } from "../../utils/hook/useAxios";

// import AOS from "aos";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import togglePassword from "../../utils/tools/togglePassword";
import MetamaskAssociationCTA from "../MetamaskAssociationCTA";

function AssociationAccount({ data }) {
  const axios = useAxios();
  const [editing, setEditing] = useState(false);
  const [id, setId] = useState(null);
  const [youremail, setYouremail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setlastName] = useState(null);
  const [values, setValues] = useState({
    youremail: "",
    newemail: "",
    confirmnewemail: "",
    actualpassword: "",
    newpassword: "",
    confirmnewpassword: "",
  });
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
    togglePassword();

    // AOS.init({
    //   duration: 1000,
    // });
  }, []);

  const [goodleAssociated, setGoogleAssociated] = useState(false);
  const [facebookAssociated, setFacebookAssociated] = useState(false);
  const [metamaskAssociated, setMetamaskAssociated] = useState(false);
  const [walletVerified, setWalletVerified] = useState(false);

  useEffect(() => {
    let fetchReviews = async () => {
      let response = await axios.get("/");
      if (window.localStorage.getItem("userdata")) {
        const retrievedObject = JSON.parse(
          window.localStorage.getItem("userdata")
        );

        response.data = retrievedObject;
      }

      setYouremail(response.data.email);
      if (response.data.facebookId) {
        setFacebookAssociated(true);
      }

      if (response.data.walletVerified) {
        setWalletVerified(true);
      }

      if (response.data.metamaskWallet) {
        setMetamaskAssociated(true);
      }

      if (response.data.googleId) {
        setGoogleAssociated(true);
      }
      setId(response.data.id);
      setFirstName(response.data.first_name);
      setlastName(response.data.last_name);

      setValues({
        youremail: response.data.email,
        newemail: "",
        confirmnewemail: "",
        actualpassword: "",
        newpassword: "",
        confirmnewpassword: "",
      });
    };
    fetchReviews();
  }, [youremail]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // const handleFacebookClick = (e) => {
  //   setValues({ ...values, [e.target.name]: e.target.value });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataUser = {
      id: id,
      first_name: firstName,
      last_name: lastName,
      email: values.newemail,
      password: "",
    };

    const passwordUserData = {
      id: id,
      first_name: firstName,
      last_name: lastName,
      email: "",
      password: values.newpassword,
    };

    if (values.newemail === values.confirmnewemail && values.newemail !== "") {
      axios
        .post("/data/update-email", dataUser)
        .then((res) => {
          // console.log(res);

          if (res.data.message === "emailExist") {
            toast.error("Email already exist", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            toast.success("data saved sucessfully", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            window.location.href = "/update-email-confirmation";
          }
        })
        .catch((err) => {
          // console.log(err);
          toast.error("Email already exist, Verify if your email is correct", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else if (
      values.newpassword === values.confirmnewpassword &&
      values.newpassword !== ""
    ) {
      axios
        .post("/data/update-email", passwordUserData)
        .then((res) => {
          // console.log(res);

          toast.success("data saved sucessfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // window.location.href = "/update-email-confirmation";
          setEditing(false);
        })
        .catch((err) => {
          // console.log(err);
          toast.error("server url don't not exist", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else {
      if (values.newemail != values.confirmnewemail) {
        toast.error("Emails must be same", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  async function ageCheck() {
    const provider = await detectConcordiumProvider();
    try {
      const account = await provider.connect();

      if (!account) {
        alert("Please connect concordium");
      }

      // TODO Replace add range with addMinimumAge(13) when SDK is fixed.
      const statementBuilder =
        new Web3StatementBuilder().addForIdentityCredentials(
          [0, 1, 2, 3, 4, 5],
          (b) => b.addRange("dob", MIN_DATE, getPastDate(13, 1))
        );
      const statement = statementBuilder.getStatements();
      // In a production scenario the challenge should not be hardcoded, in order to avoid accepting proofs created for other contexts.
      const challenge =
        "BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB";

      // Requesting ID proof to check if user is 18 years old
      provider
        .requestVerifiablePresentation(challenge, statement)
        .then(() => {
          // TODO: Verifiy the proof
          // User is 18 year old, show something
          axios
            .get(`/${id}/verify-wallet/` + account)
            .then((window.location.href = "/account/settings"));
          // isverified(flag)
        })
        .catch(() => {
          // User is not 18 years old
          // alert("Age verification was not completed. Please complete the verification")
        });
    } catch (error) {
      console.error(error); // from creation or business logic
      alert("Please connect");
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} action="">
        <div
          class="personal-data mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <div class="title">
            <p class="lead me-auto">{t("AccountProfileEmailSocial")}</p>

            {editing ? (
              <>
                <button type="submit" class="btn btn-success ms-2">
                  {t("AccountProfileSaveButton")}
                </button>
                <button
                  type="cancel"
                  class="btn btn-danger ms-2"
                  onClick={handleCancel}
                >
                  {t("AccountProfileCancelButton")}
                </button>
              </>
            ) : (
              <button class="btn btn-outline-primary" onClick={handleEdit}>
                {t("AccountProfileEditButton")}
              </button>
            )}
          </div>
          <div class="row">
            <div class="social-connect col-md-6 mb-4 order-2 order-lg-1">
              <div class="d-flex flex-column mx-auto gap-2">
                {/* {facebookAssociated === true ? (
                  <a
                    href=""
                    className="btn btn-success disabled"
                    disabled={true}
                  >
                    <i className="flaticon-facebook"></i>
                    {t("AccountProfileEmailSocialFacebookOK")}
                    <i class="flaticon-check ms-auto"></i>
                  </a>
                ) : (
                  <a
                    href="https://api.veritatrust.com/v1/auth/facebook/associate/facebook"
                    className="btn btn-outline-primary"
                  >
                    <i className="flaticon-facebook"></i>{" "}
                    {t("AccountProfileEmailSocialFacebook")}
                  </a>
                )} */}

                {goodleAssociated === true ? (
                  <a
                    href=""
                    className="btn btn-success disabled"
                    disabled={true}
                  >
                    <i className="flaticon-google"></i>{" "}
                    {t("AccountProfileEmailSocialGoogleOK")}
                    <i class="flaticon-check ms-auto"></i>
                  </a>
                ) : (
                  <a
                    href="https://api.veritatrust.com/v1/associate/google"
                    className="btn btn-outline-primary"
                  >
                    <i className="flaticon-google"></i>{" "}
                    {t("AccountProfileEmailSocialGoogle")}
                  </a>
                )}

                <MetamaskAssociationCTA
                  metamaskAssociatedProp={metamaskAssociated}
                  idprop={id}
                ></MetamaskAssociationCTA>

                {walletVerified === true ? (
                  <a
                    href=""
                    className="btn btn-success disabled"
                    disabled={true}
                  >
                    <i className="flaticon-blockchain"></i>
                    {"Verified with Concordium wallet"}
                    <i class="flaticon-check ms-auto"></i>
                  </a>
                ) : (
                  <a onClick={ageCheck} className="btn btn-outline-primary">
                    <i className="flaticon-blockchain"></i>{" "}
                    {"Verify with Concordium wallet"}
                  </a>
                )}

                <a href="#" class="btn btn-outline-primary d-none">
                  <i class="flaticon-apple"></i>Connect with Apple
                </a>
              </div>
              <hr class="d-md-none" />
            </div>
            <div class="col-md-6 mb-3 order-1 order-lg-2">
              <div class="usermail mb-3">
                <label for="usermail">{t("AccountProfileYourEmail")}</label>
                <input
                  id="usermail"
                  class="form-control"
                  type="email"
                  name="youremail"
                  value={values.youremail}
                  disabled={!editing}
                />
              </div>

              {editing === false ? (
                <div class="newpassword mb-3">
                  <label for="usermail">
                    {t("AccountProfileYourPassword")}
                  </label>
                  <input
                    class="form-control"
                    type="password"
                    value="**********************"
                    disabled={!editing}
                  />
                </div>
              ) : (
                <p>{t("AccountProfileUpdateEmail")}</p>
              )}

              {editing === true && (
                <>
                  <div class="newmail mb-3">
                    <label for="newmail">{t("AccountProfileNewEmail")}</label>
                    <input
                      id="newmail"
                      class="form-control "
                      type="email"
                      name="newemail"
                      value={values.newemail}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="confirmnewmail mb-3">
                    <label for="confirmnewmail">
                      {t("AccountProfileConfirmEmail")}
                    </label>
                    <input
                      id="confirmnewmail"
                      class="form-control "
                      type="email"
                      name="confirmnewemail"
                      value={values.confirmnewemail}
                      onChange={handleChange}
                    />
                  </div>

                  <hr />
                  <p>{t("AccountProfileUpdatePassword")}</p>
                  <div class="actualpassword mb-3">
                    <label for="actualpassword">
                      {t("AccountProfileActualPassword")}
                    </label>
                    <div class="input-group mb-3 ">
                      <input
                        type="password"
                        class="form-control "
                        name="actualpassword"
                        value={values.actualpassword}
                        onChange={handleChange}
                        id="actualpassword"
                      />
                      <span class="input-group-text bg-success toggle-password text-white border-success">
                        <i class="flaticon-hide"></i>
                      </span>
                    </div>
                  </div>

                  <div class="newpassword mb-3">
                    <label for="newpassword">
                      {t("AccountProfileNewPassword")}
                    </label>
                    <div class="input-group mb-3 ">
                      <input
                        type="password"
                        class="form-control "
                        name="newpassword"
                        value={values.newpassword}
                        onChange={handleChange}
                        id="newpassword"
                      />
                      <span class="input-group-text bg-success toggle-password text-white border-success">
                        <i class="flaticon-hide"></i>
                      </span>
                    </div>
                  </div>
                  <div class="confirmnewpassword mb-3">
                    <label for="confirmnewpassword">
                      {t("AccountProfileConfirmPassword")}
                    </label>
                    <div class="input-group mb-3 ">
                      <input
                        type="password"
                        class="form-control "
                        name="confirmnewpassword"
                        value={values.confirmnewpassword}
                        onChange={handleChange}
                        id="confirmnewpassword"
                      />
                      <span class="input-group-text bg-success toggle-password text-white border-success">
                        <i class="flaticon-hide"></i>
                      </span>
                    </div>
                  </div>

                  {editing ? (
                    <>
                      <button type="submit" class="btn btn-success ms-2">
                        {t("AccountProfileSaveButton")}
                      </button>
                      <button
                        type="cancel"
                        class="btn btn-danger ms-2"
                        onClick={handleCancel}
                      >
                        {t("AccountProfileCancelButton")}
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="d-md-none d-flex justify-content-end mt-3">
                    {editing ? (
                      <>
                        <button type="submit" className="btn btn-success ms-2">
                          {t("AccountProfileSaveButton")}{" "}
                        </button>
                        <button
                          className="btn btn-danger ms-2"
                          onClick={handleCancel}
                        >
                          {t("AccountProfileCancelButton")}
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AssociationAccount;
