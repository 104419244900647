// Import axios (ou une autre bibliothèque HTTP, ou utiliser fetch)
import axios from "axios";

/**
 * Vérifie la toxicité d'un texte via l'API Perspective.
 * @param {string} text - Le texte à analyser.
 * @returns {Promise<boolean>} - Retourne `true` si le texte est toxique, sinon `false`.
 */
export const checkToxicity = async (text) => {
  const API_URL =
    "https://commentanalyzer.googleapis.com/v1alpha1/comments:analyze";
  const API_KEY = "AIzaSyCfnD-b3VQ4OCMbis-d-DYJK2Qz4UhHLgg"; // Remplacez par votre clé API.

  if (!text) {
    console.warn("Aucun texte fourni pour l'analyse.");
    return false;
  }

  try {
    const response = await axios.post(`${API_URL}?key=${API_KEY}`, {
      comment: { text },
      languages: ["en", "fr", "it"],
      requestedAttributes: { TOXICITY: {} },
    });

    // Extraire le score de toxicité
    const toxicityScore =
      response.data.attributeScores.TOXICITY.summaryScore.value;

    // Définir un seuil pour la toxicité (par exemple, 0.5 ou 50 %)
    const threshold = 0.2;

    // Retourne `true` si le score dépasse le seuil, sinon `false`
    return toxicityScore >= threshold;
  } catch (error) {
    console.error("Erreur lors de la vérification de la toxicité :", error);
    return false; // Retourne `false` en cas d'erreur
  }
};

// Exemple d'utilisation dans un composant React
/*
import React, { useState } from "react";
import { checkToxicity } from "./path/to/this/file";

const TextAnalyzer = () => {
  const [text, setText] = useState("");
  const [isToxic, setIsToxic] = useState(null);

  const analyzeText = async () => {
    const result = await checkToxicity(text);
    setIsToxic(result);
  };

  return (
    <div>
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="Entrez votre texte ici"
      />
      <button onClick={analyzeText}>Analyser</button>
      {isToxic !== null && (
        <p>{isToxic ? "Le texte est toxique." : "Le texte n'est pas toxique."}</p>
      )}
    </div>
  );
};

export default TextAnalyzer;
*/
