import CryptoJS from "crypto-js";
import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import add_image from "../../assets/add_image.webp";
import NoPicture from "../../assets/no-picture.png";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
import { checkToxicity } from "../../utils/hook/useModerationContext";
import CreateProofPurchaseInput from "../CreateProofPurchaseInput";

const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAt/3R6qvoXNLbaLurkSHr
zEV+n850tYp0RNUNby7tBDs1euwIYjUIvd2RmGA6IDtCu/WNVxa5i0XXEepI3O6S
P8im++gjkDfgBiicCyrAgjl9ZN7JAZy8dSYaw0h/YzfU3aN/l04q/GSMFNaHSzFt
HjfrOfAsEgAqNl3YgdMR3MqPfFZsm7N0BEZhfwW/y7HO6+aX45H0KBs4fM8x08NL
Y0dJGsT3lN72FG/KBh2t+9DbidcIB5R5I89LZeLudsb/zMNy94uR4jktkfP2zw0g
bTkwDe7E/Y5ETN7N09KMAroxCBCifKSSjhTHfuUTM15+cYpassxiMx3pZB/eSD5a
qQIDAQAB
-----END PUBLIC KEY-----`;

const noteByTextSize = {
  80: { title: "Weak", className: "bad" },
  160: { title: "Average", className: "not_bad" },
  240: { title: "Good", className: "good" },
  320: { title: "Very Good", className: "very_good" },
  400: { title: "Excellent", className: "excellent" },
};

const textSizeByNote = {
  BAD: 80,
  NOT_BAD: 160,
  GOOD: 240,
  VERY_GOOD: 320,
  EXCELLENT: 400,
};

function OrgReviewForm({ data, textAreaId }) {
  const { t, i18n } = useTranslation();
  const [text, setText] = useState("");
  const [note, setNote] = useState(noteByTextSize[textSizeByNote.BAD]);
  const [previousClassName, setPreviousClassName] = useState(
    noteByTextSize[textSizeByNote.BAD].className
  );
  const [noteReview, setNoteReview] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [ratingReview, setRatingReview] = useState(1);
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState("");
  const [proof, setProof] = useState();
  const [fileNameProof, setFileNameProof] = useState("");
  const [date, setDate] = useState(new Date());
  const merchandRef = useRef(null);
  const reviewValueRef = useRef(null);
  const reviewValueTextRef = useRef(null);
  const [userData, setUserData] = useState({}); //useSelector((state) => state.auth.user);
  const axios = useAxios();
  const [resetInput, setResetInput] = useState(0);
  const [noteTitles, setNoteTitles] = useState([
    "Very Bad",
    "Bad",
    "Average",
    "Good",
    "Excellent",
  ]);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);

  const [langId, setLangId] = useState(null);

  const { lang } = useParams();
  useEffect(() => {
    i18n.changeLanguage(lang);
    axios
      .get(`/langs/${lang}`)
      .then((response) => {
        setLangId(response.data[0].id);
        //("lang selected", response.data);
      })
      .catch((error) => {
        //(error);
      });
  }, []);

  useEffect(() => {
    let fetchReviews = async () => {
      const response = await axios.get("/");
      const res = await axios.get("/auth/check-auth");
      setIsAuthenticated(res.data.isAuthenticated);
      //(isAuthenticated);

      setUserData(response.data);
    };
    fetchReviews();
  }, []);

  useEffect(() => {
    const textArea = document.getElementById(textAreaId);
    if (!textArea) {
      //(textArea);
      throw new Error(
        `The textarea element with id ${textAreaId} does not exist`
      );
    }

    const ReviewNote = document.getElementById("noteReview");

    setNoteReview(ReviewNote);

    //("noteReview");
    //(ReviewNote);

    textArea.addEventListener("input", handleInput);
    return () => textArea.removeEventListener("input", handleInput);
  }, []);

  const handleInput = (e) => {
    const text = e.target.value.trim();
    setText(text);

    const textLength = text.length;
    const currentNote = getNoteByTextSize(textLength);
    setNote(currentNote);

    if (previousClassName === currentNote.className) return;

    noteReview.classList.remove(previousClassName);
    noteReview.classList.add(currentNote.className);
    setPreviousClassName(currentNote.className);
  };

  const getNoteByTextSize = (textLength) => {
    if (textLength < textSizeByNote.BAD) {
      return noteByTextSize[textSizeByNote.BAD];
    }

    for (let key in textSizeByNote) {
      if (textLength < textSizeByNote[key]) {
        return noteByTextSize[textSizeByNote[key]];
      }
    }

    return noteByTextSize[textSizeByNote.EXCELLENT];
  };

  // Review stars

  useEffect(() => {
    const merchand = merchandRef.current;
    const stars = merchand.querySelectorAll(".star-review");
    const reviewValue = reviewValueRef.current;
    const reviewValueText = reviewValueTextRef.current;
    const starsArray = Array.from(stars);
    starsArray.forEach((star, index) => {
      fillStarsWithSelectedValue(index + 1);
      handleClickOnReview(star);
      handleHoverOnReview(star);
    });

    function handleHoverOnReview(star) {
      star.addEventListener("mouseover", () => {
        const rating = star?.dataset?.index;
        fillStars(rating);
        emptyStars(rating);
      });

      star.addEventListener("mouseout", () => {
        const rating = star?.dataset?.index;
        emptyStars(rating);
        fillStarsWithSelectedValue(merchand.dataset.selected);
      });
    }

    function handleClickOnReview(star) {
      star.addEventListener("click", () => {
        const rating = star?.dataset?.index;
        fillStars(rating);
        emptyStars(rating);
        merchand.dataset.selected = rating;
        setRatingReview(rating);

        reviewValue.innerHTML = rating;
        if (rating === 1)
          reviewValueText.innerHTML = " <strong> star: </strong>Very bad";
        if (rating === 2)
          reviewValueText.innerHTML = " <strong> stars: </strong>Bad";
        if (rating === 3)
          reviewValueText.innerHTML = " <strong> stars: </strong>Average";
        if (rating === 4)
          reviewValueText.innerHTML = " <strong> stars: </strong>Good";
        if (rating === 5)
          reviewValueText.innerHTML = " <strong> stars: </strong>Excellent";
      });
    }

    function fillStarsWithSelectedValue(index) {
      if (Number(index) !== Number(merchand.dataset.selected)) return;
      fillStars(Number(merchand.dataset.selected));
      emptyStars(Number(merchand.dataset.selected));
    }

    function emptyStars(rating) {
      const afterStar = starsArray.filter(
        (s) => Number(s.dataset.index) > Number(rating)
      );
      afterStar.forEach((s) => s.classList.remove("star-filled"));
    }

    function fillStars(rating) {
      const beforeStar = starsArray.filter(
        (s) => Number(s.dataset.index) <= Number(rating)
      );

      beforeStar.forEach((s) => s.classList.add("star-filled"));
    }
  }, []);

  //review form for product code method using useRef

  const title = useRef(null);
  const content = useRef(null);
  const experienceDate = useRef(null);
  const image = useRef();
  const fileInputRef = useRef();
  const [reviewObj, setReviewObj] = useState({
    title: "",
    content: "",
    rating: "0",
    experienceDate: date.toISOString().slice(0, 10),
    image: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    const newFiles = [...file]; // Créez une copie du tableau existant
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]); // Ajoutez chaque fichier à la copie
    }

    setFile(newFiles);
    setFileName(e.target.files[0].name);
    setProof(e.target.files[0]);
    setFileNameProof(e.target.files[0].name);
    createMiniatureForImage(e);
  };

  const [values, setValues] = useState({
    title: "",
    content: "",
    experienceDate: date.toISOString().slice(0, 10),
    image: "",
  });

  //comportement
  const [imagereview, setImagereview] = useState([]);

  //handle change image and create miniatures
  const createMiniatureForImage = (e) => {
    const stateImg = [];

    Array.from(e.target.files).map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const imageUrl = reader.result;
        const state = [...imagereview];
        state.push(imageUrl);
        setImagereview(state);
      };
    });
  };

  const { product_name } = useParams();
  const jobid = Math.floor(Math.random() * 100000);

  const [isToxic, setIsToxic] = useState(null);
  const [errorCode, setErrorCode] = useState(200);

  const analyzeText = async (Text) => {
    const result = await checkToxicity(Text);
    setIsToxic(result);
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    title.current.value = "";
    content.current.value = "";

    image.current.value = "";
    setValues({
      title: "",
      content: "",
      experienceDate: date.toISOString().slice(0, 10),
      image: "",
    });
    setReviewObj({
      title: values.title,
      content: values.content,
      rating: ratingReview,
      experienceDate: values.experienceDate,
      image: values.image,
    });

    const review = {
      product_id: "",
      product_name: product_name,
      title: values.title,
      content: text,
      rating: ratingReview,
      experienceDate: values.experienceDate,
      image: values.image,
    };
    //(review);
    setLoading(true);

    const contentToxic = await analyzeText(values.title + " " + text);

    if (contentToxic) {
      toast.error(
        "the system detected toxic terms in your content that is not suitable for this platform, please rephrase",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    } else {
      const formData = new FormData();
      // formData.append("files", file);
      for (let i = 0; i < file.length; i++) {
        formData.append(`files${i}`, file[i]); // Ajoutez chaque fichier à la copie
      }
      formData.append("fileName", fileName);
      formData.append("product_id", parseInt(product_name.split("-")[0]));
      formData.append("product_name", formatSEOFriendly(product_name));
      formData.append("title", values.title);
      formData.append("content", text);
      formData.append("rating", ratingReview);
      formData.append("job_id", jobid);
      //formData.append("experienceDate", values.experienceDate);
      formData.append("user_id", userData["id"]);
      formData.append("lang_id", langId);
      formData.append("isOrganic", 1);

      if (values.title === "" || text === "" || userData["id"] === null) {
        toast.error("Data incomplete", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        axios
          .post("/data/product-review/", formData, false)
          .then((res) => {
            setErrorCode(res.status);

            if (res.status === 207) {
              toast.error(
                "Review content or title is too similar to an existing review.",
                {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                }
              );
            } else {
              //(res);
              if (res.status === 200) {
                toast.success("Your review have been saved successfully", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });

                axios
                  .post(
                    "/data/transaction-create",
                    {
                      user_id: userData["id"],
                      merchant_id: null,
                      order_id: null,
                      transaction_id: jobid,
                      proofOfPurchase: proofOfPurchase,
                    },
                    false
                  )
                  .then((res) => {
                    //(res);
                    window.location.href = "/account";
                  })
                  .catch((err) => {
                    if (errorCode == 403) {
                      toast.error(
                        "Review content or title is too similar to an existing review.",
                        {
                          position: "top-center",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "colored",
                        }
                      );
                    }
                  });
              } else {
                toast.error("Error found merchant review not created", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              }
            }

            //  window.location.href = "/account";

            // axios
            //   .post(
            //     "/LastReviewRoutes",
            //     {
            //       userId: userData["id"],
            //       LastReviewSubmitDate: new Date(),
            //     },
            //     {
            //       headers: {
            //         "Content-Type": "multipart/form-data",
            //       },
            //     }
            //   )
            //   .then((res) => {
            //     //(res);
            //     window.location.href = "/account";
            //   })
            //   .catch((err) => {});
          })
          .catch((err) => {
            //(err);
            toast.error("Error found merchant review bot created", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setLoading(false);
          });
      }
    }
  };

  const formatSEOFriendly = (str) => {
    const words = str.split("-");
    words.shift();

    const lowerCaseWords = words.map((word) => word.toLowerCase());
    const name = lowerCaseWords.join(" ");

    return name;
  };

  // Continue with social media
  // Continue with social media

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  //comportement
  function handleChangeConnect(e) {
    const { name, value } = e.target;
    setUser((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  const handleConnect = (e) => {
    e.preventDefault();

    const payload = JSON.stringify(user);
    const encryptedPayload = CryptoJS.AES.encrypt(
      payload,
      publicKey
    ).toString();

    axios
      .post("/login", user, true)
      .then((res) => {
        if (res.data["success"] == true || res.data.token) {
          //(res.data);
          //window.location.href = "/account";
          window.location.reload(); // Redirige vers la même URL
        } else {
          //("Error to connect");
        }
      })
      .catch((err) => {
        //(err);
        toast.error("Invalid password or username", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const [proofOfPurchase, setProofOfPurchase] = useState(null); // État pour stocker le fichier

  const updateProofOfPurchase = (file) => {
    setProofOfPurchase(file);
  };
  function storeReturnToClient() {
    const currentUrl = window.location.href;

    // Stocker l'URL actuelle dans un cookie ou dans le stockage local
    // par exemple, en utilisant document.cookie ou window.localStorage
    // Assurez-vous de gérer correctement l'expiration et la sécurité des cookies.
    // Stocker l'URL actuelle dans le stockage local
    localStorage.setItem("returnTo", currentUrl);
  }

  return (
    <>
      {
        <section className="container">
          <div className="row py-5 form">
            {!isAuthenticated && (
              <div className="col-12 col-lg-6 mx-auto">
                <h3 className="text-center">Connect to continue</h3>
                <div className="d-flex flex-column flex-md-row gap-2 justify-content-center">
                  <a
                    className="btn btn-primary"
                    style={{ backgroundColor: "#002d6b" }}
                    href="https://api.veritatrust.com/v1/auth/facebook/reviewform"
                  >
                    {" "}
                    {t("SigninWithFacebook")}{" "}
                  </a>

                  <a
                    className="btn btn-primary"
                    style={{ backgroundColor: "#002d6b" }}
                    href={`https://api.veritatrust.com/v1/auth/google/reviewform?returnTo=${encodeURIComponent(
                      window.location.href
                    )}`}
                    onClick={() => storeReturnToClient()}
                  >
                    {" "}
                    {t("SigninWithGoogle")}
                  </a>

                  <a href="#" className="btn btn-primary d-none">
                    Sign in with Apple
                  </a>
                  <div class="dropdown">
                    <button
                      type="button"
                      class="btn btn-primary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                    >
                      {t("AddReviewForBetaTesterSignWithEmail")}
                    </button>
                    <div class="dropdown-menu dropdown-menu-end">
                      <form
                        className="px-3"
                        id="login"
                        onSubmit={handleConnect}
                      >
                        <div className="">
                          <div className="input-group">
                            <input
                              type="email"
                              className="form-control"
                              value={user.email}
                              onChange={handleChangeConnect}
                              name="email"
                              id="usermail"
                              aria-describedby="emailHelp"
                              title="User Email"
                              placeholder="User Email"
                            />
                            <span className="input-group-text bg-success text-white border-success">
                              <i className="flaticon-user"></i>
                            </span>
                          </div>
                          <div id="emailHelp" className="form-text mb-3"></div>
                          <div className="input-group mb-3 ">
                            <input
                              type="password"
                              className="form-control"
                              value={user.password}
                              onChange={handleChangeConnect}
                              id="userpassword"
                              name="password"
                              title="Password"
                              placeholder="Password"
                            />
                            <span className="input-group-text bg-success toggle-password text-white border-success">
                              <i className="flaticon-hide"></i>
                            </span>
                          </div>
                          <div className="mb-3 form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="rememberme"
                              checked={user.stayConnected && true}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="rememberme"
                            >
                              {t("HeaderNavbarConnected")}
                            </label>
                          </div>
                          <div className="d-grid gap-2">
                            <button
                              type="submit"
                              className="btn-block btn btn-success text-white"
                              id="userbutton"
                            >
                              {t("HeaderSignin")}
                            </button>
                          </div>
                        </div>
                      </form>
                      <div class="dropdown-divider"></div>
                      <a
                        class="dropdown-item"
                        href={"/" + t("lang") + "/signup"}
                      >
                        {t("AddReviewForBetaTesterNewAroundHere")}
                      </a>
                      <Link className="dropdown-item" to="/resetpassword">
                        {t("HeaderNavbarPass")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <form id="reviewform" onSubmit={handleSubmit}>
              <div className="container">
                <div className="row d-flex align-items-center">
                  <div className="col-11 col-lg-8 pt-3 mx-auto text-center">
                    <h3>
                      {t("productformTitle")}{" "}
                      <span>{formatSEOFriendly(product_name)}</span>
                    </h3>
                    <p>{t("productformActionTitle")}</p>
                  </div>

                  <div
                    className={
                      isAuthenticated
                        ? "col-11 col-lg-8 py-4 px-lg-4 mx-auto bg-light rounded-5"
                        : "col-11 col-lg-8 py-4 px-lg-4 mx-auto bg-light rounded-5 blur-8"
                    }
                  >
                    <div className="form__header">
                      <div className="row d-flex align-items-center">
                        <div className="col-6 col-md-4 col-xl-3 d-flex rounded-4 border bg-white visuel-mark g-0 mx-auto">
                          <img
                            className="align-self-center"
                            src={data.aw_image_url || NoPicture}
                            alt="Product name"
                          />
                        </div>
                        <div className="col-12 col-md-8 col-xl-9">
                          <div className="form__header__note">
                            <p className="lead mb-0">
                              {formatSEOFriendly(product_name)}
                            </p>

                            <p className="mb-0">
                              {t("AddReviewForBeaTesterMsgt2")}?
                            </p>

                            <div
                              className="form__star mb-3 d-flex justify-content-start"
                              id="review"
                              data-review="merchand"
                              ref={merchandRef}
                              data-selected="0"
                            >
                              <div
                                className="star-review star-default star-empty star-filled"
                                data-index="1"
                              ></div>
                              <div
                                className="star-review star-default star-empty"
                                data-index="2"
                              ></div>
                              <div
                                className="star-review star-default star-empty"
                                data-index="3"
                              ></div>
                              <div
                                className="star-review star-default star-empty"
                                data-index="4"
                              ></div>
                              <div
                                className="star-review star-default star-empty"
                                data-index="5"
                              ></div>
                            </div>

                            <p>
                              <span id="review-value" ref={reviewValueRef}>
                                {ratingReview}
                              </span>{" "}
                              <span ref={reviewValueTextRef}>
                                {ratingReview == 1 ? (
                                  <Trans
                                    i18nKey="AddreviewMerchant1star"
                                    components={{
                                      1: <strong></strong>,
                                    }}
                                  />
                                ) : ratingReview == 2 ? (
                                  <Trans
                                    i18nKey="AddreviewMerchant2star"
                                    components={{
                                      1: <strong></strong>,
                                    }}
                                  />
                                ) : ratingReview == 3 ? (
                                  <Trans
                                    i18nKey="AddreviewMerchant3star"
                                    components={{
                                      1: <strong></strong>,
                                    }}
                                  />
                                ) : ratingReview == 4 ? (
                                  <Trans
                                    i18nKey="AddreviewMerchant4star"
                                    components={{
                                      1: <strong></strong>,
                                    }}
                                  />
                                ) : ratingReview == 5 ? (
                                  <Trans
                                    i18nKey="AddreviewMerchant5star"
                                    components={{
                                      1: <strong></strong>,
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="form__content">
                        <p className="lead mt-3 mb-0">
                          {t("AddReviewForBetaTesterWriteTitle")}
                        </p>
                        <input
                          className="form-control"
                          type="text"
                          id="title"
                          name="title"
                          required=""
                          ref={title}
                          value={values.title}
                          onChange={handleChange}
                          disabled={!isAuthenticated}
                        />
                        <p className="lead mb-0">
                          {t("AddReviewForBetaTesterWriteContent")}
                        </p>
                        {/* <p>
                      Focus on being factual and objective. Don't use aggressive
                      language and don't post personal details.
                    </p> */}
                        <p id="note_review" className="">
                          {t("AddReviewForBetaTesterMsg3")}{" "}
                          <span id="noteReview" className={note.className}>
                            {note.title}
                          </span>{" "}
                        </p>
                        <textarea
                          className="form-control"
                          name="content"
                          rows="10"
                          placeholder={t("AddreviewProductTitleContentField")}
                          id="content"
                          onChange={handleInput}
                          ref={content}
                          disabled={!isAuthenticated}
                        ></textarea>
                        <p className="">
                          <Trans
                            i18nKey="AddReviewForBetaTesterAddPhotos"
                            components={{
                              1: <span className="text-muted"></span>,
                            }}
                          />
                        </p>
                        <div className="row mb-4">
                          {imagereview.length > 0 ? (
                            // (imagereview[length-1].map((img) => {
                            <div className="col-6 col-lg">
                              <div className="d-flex rounded-4 border bg-white visuel-mark">
                                <img
                                  className="align-self-center"
                                  src={imagereview[imagereview.length - 1]}
                                  alt="Product name"
                                  id="img2"
                                />
                              </div>
                            </div>
                          ) : (
                            // }))
                            <div className="col-6 col-lg">
                              <div className="d-flex rounded-4 border bg-white visuel-mark">
                                <img
                                  className="align-self-center"
                                  src={add_image}
                                  alt="Product name"
                                  id="img2"
                                />
                              </div>
                            </div>
                          )}

                          {imagereview.length > 1 ? (
                            // (imagereview[length-1].map((img) => {
                            <div className="col-6 col-lg">
                              <div className="d-flex rounded-4 border bg-white visuel-mark">
                                <img
                                  className="align-self-center"
                                  src={imagereview[imagereview.length - 2]}
                                  alt="Product name"
                                  id="img2"
                                />
                              </div>
                            </div>
                          ) : (
                            // }))
                            <div className="col-6 col-lg">
                              <div className="d-flex rounded-4 border bg-white visuel-mark">
                                <img
                                  className="align-self-center"
                                  src={add_image}
                                  alt="Product name"
                                  id="img2"
                                />
                              </div>
                            </div>
                          )}

                          {imagePreview && (
                            <img
                              src={imagePreview}
                              alt="Preview"
                              style={{ maxWidth: "100%" }}
                            />
                          )}
                          <div className="col-6 col-lg">
                            <label className="add__photo rounded-3 border w-100 text-center d-flex">
                              <div className="m-auto">
                                <i className="flaticon-galerie"></i>
                                <div className="">
                                  + {t("productFormAddPhoto")}
                                </div>
                                <input
                                  accept="image/jpeg, image/tiff, image/webp, image/png, video/mp4, video/quicktime"
                                  type="file"
                                  id="image"
                                  className="d-none"
                                  ref={image}
                                  onChange={handleChange}
                                  disabled={!isAuthenticated}
                                />
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <CreateProofPurchaseInput
                        updateProofOfPurchase={updateProofOfPurchase}
                        resetInput={resetInput}
                      />

                      <p className="text-muted">
                        {t("AddReviewForBetaTesterMsg6")}
                      </p>

                      <div className="d-none" id="root-submit">
                        <div className="row d-flex align-items-center">
                          <div className="col-11 col-lg-8 pt-3 mx-auto text-center">
                            <h3>Submit the review</h3>
                          </div>
                          <div className="col-11 col-lg-8 py-4 px-lg-6 mx-auto">
                            <div className="form__account">
                              <div className="row d-flex flex-column align-items-center">
                                <div
                                  className="col-12 col-md-6 d-flex flex-column mx-auto gap-2"
                                  id="module-connect"
                                >
                                  <button className="btn btn-primary">
                                    Sign in with Facebook
                                  </button>
                                  <button className="btn btn-primary">
                                    Sign in with Google
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    id="metaloginbtn"
                                  >
                                    Sign in with Metamask
                                  </button>
                                  <a href="#" className="d-none">
                                    Sign in with Apple
                                  </a>
                                </div>
                                <div className="d-none">
                                  <div className="orbymail text-center">
                                    <div className="separator">
                                      Use your email address
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="row d-flex align-items-center"
                                  id="sumbit-email"
                                ></div>
                                <div className="d-none" id="message-confirm">
                                  <div className="me-3">
                                    <i className="flaticon-check"></i>
                                  </div>
                                  <div>Success Message</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>

                    <div
                      className="col-12 col-md-6 d-flex flex-column mx-auto gap-2"
                      id="publish"
                    >
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isAuthenticated}
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              aria-hidden="true"
                            ></span>
                            <span role="status">Loading...</span>
                          </>
                        ) : (
                          t("AddreviewMerchantButton")
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      }

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default OrgReviewForm;
