import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";

import { Link } from "react-router-dom";
import DashboardDiv from "../../components/DashboardDiv";
import LastReview from "../../components/LastReview";
import NavList from "../../components/NavList";
import StateProfile from "../../components/StateProfile";
import UserReward from "../../components/UserReward";
import { useAxios } from "../../utils/hook/useAxios";
//import AOS from "aos";
//import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function UserDashboard() {
  const axios = useAxios();

  const [stars, setStars] = useState(0);
  const [nb, setNb] = useState(0);
  const [isBasicInfo, setIsBasicInfo] = useState(false);
  const [isMetamask, setIsMetamask] = useState(false);
  const [isKYC, setIsKYC] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);

    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   AOS.init({
  //     duration: 1000,
  //   });
  // });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setStars(params.get("stars"));
  }, []);

  const [reviews, setReviews] = useState([]);
  const [ratings, setRatings] = useState("");

  const [UserRewardData, setUserRewardData] = useState({
    fName: "",
    lName: "",
    totalReward: 0,
    approuvedRaward: 0,
    pendingReward: 0,
    levelAccount: 1,
    fLevelAccount: 2,
    reviewNb: 21,
  });

  useEffect(() => {
    let fetchReviews = async () => {
      let response = await axios.get("/");

      if (window.localStorage.getItem("userdata")) {
        const retrievedObject = JSON.parse(
          window.localStorage.getItem("userdata")
        );
        response.data = retrievedObject;
      }

      const res = await axios.get("/lreview-dashboard/" + response.data["id"]);
      setReviews(res.data.slice(0, 5));

      // console.log(reviews);

      if (res.data.length > 0) {
        const rewardData = {
          fName: res.data[0].first_name,
          lName: res.data[0].last_name,
          totalReward: res.data[0].total,
          approuvedRaward: res.data[0].total_published,
          pendingReward: res.data[0].total_pending,
          levelAccount: res.data[0].level_account,
          fLevelAccount: res.data[0].level_account + 1,
          reviewNb: res.data.length,
        };

        setUserRewardData(rewardData);

        setNb(res.data[0].total_published);
        // console.log(UserRewardData);
      }
    };
    fetchReviews();
  }, [ratings]);

  useEffect(() => {
    let fetchReviews = async () => {
      const response = await axios.get("/");
      if (
        response.data.nickname &&
        response.data.first_name &&
        response.data.last_name &&
        response.data.dateNaissance &&
        response.data.localAdress &&
        response.data.phoneNumber &&
        response.data.zip &&
        response.data.city &&
        response.data.country &&
        response.data.currency
      ) {
        setIsBasicInfo(true);
      }

      if (response.data.metamaskWallet) {
        setIsMetamask(true);
      }

      if (response.data.userWalletAddress) {
        setIsKYC(true);
      }
    };
    fetchReviews();
  }, []);

  return (
    <>
      <DashboardDiv titlepage={t("AccountDashboard")} />
      <section className="page_content pb-5 bg-success">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4 mx-auto">
              <div className="row">
                <div className="d-none d-lg-block col-md-3">
                  <NavList></NavList>
                </div>
                <div className="dashboard col-12 col-lg-9">
                  <UserReward
                    data={UserRewardData}
                    dataLenght={nb}
                  ></UserReward>
                  <StateProfile
                    isBasicInfoComplete={isBasicInfo}
                    isKYCComplete={isKYC}
                    isMetamaskComplete={isMetamask}
                  ></StateProfile>
                  <div
                    className="mb-4 bg-white shadow rounded-5 p-4 aos animated aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom"
                  >
                    <h4 className="lead">{t("AccountYourLastReviews")}</h4>
                    {reviews.length > 0 ? (
                      <>
                        <table className="table mb-3">
                          <thead>
                            <tr>
                              <th className="review-date" scope="col">
                                <p className="mb-0">
                                  {t("AccountYourLastReviewsTableDate")}
                                </p>
                              </th>
                              <th className="review-details" scope="col">
                                <p className="mb-0">
                                  {t("AccountYourLastReviewsTableDetails")}
                                </p>
                              </th>
                              <th className="review-reward" scope="col">
                                <p className="mb-0">
                                  {t("AccountYourLastReviewsTableReward")}
                                </p>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {reviews &&
                              reviews.map((review, index) => (
                                <LastReview
                                  key={index}
                                  data={review}
                                  index={index}
                                />
                              ))}
                          </tbody>
                        </table>
                        {reviews.length >= 5 && (
                          <div className="d-grid gap-2 col-lg-6 mx-auto">
                            <Link
                              className="btn btn-success d-block mt-2 text-white"
                              to="/account/reviews"
                            >
                              {t("AccountYourLastReviewsTableSeeAll")}
                            </Link>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="callout callout-info">
                          <p className="mb-0">
                            {t("AccountReviewsReviewsNotYet")}
                          </p>
                        </div>
                        <div className="d-grid gap-2 col-lg-6 mx-auto">
                          <Link
                            className="btn btn-success d-block mt-2 text-white"
                            to={`/${window.localStorage.getItem(
                              "langPage"
                            )}/add-review`}
                          >
                            {t("AccountYourLastReviewsWriteFirstReview")}
                          </Link>{" "}
                          {/* <p className="">to get reward!</p> */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserDashboard;
