import HCaptcha from "@hcaptcha/react-hcaptcha";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";
import togglePassword from "../../utils/tools/togglePassword";

function Create() {
  const axios = useAxios();
  const captchaRef = useRef(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    togglePassword();
    i18n.changeLanguage(lang);
  }, []);

  const [inputs, setInputs] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    passwordconfirm: "",
    gender: "",
    cgv: false,
  });
  const [validation, setValidation] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    passwordconfirm: "",
    gender: "",
    cgv: "",
  });

  /* useEffect(() => {
    let fetchReviews = async () => {
      const response = await axios.get("/");
      setUserData(response.data);

      if (response.data["id"]) {
        navigate("/user-dashboard");
      }
    };
    fetchReviews();
  }, []); */

  const handleChange = (event) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //.log(inputs);

    if (token) {
      // eslint-disable-next-line no-lone-blocks
      checkValidation();
      setIsSubmitted(true);
      //.log(validation);

      if (
        validation.email === "" &&
        validation.firstname === "" &&
        validation.lastname === "" &&
        validation.password === "" &&
        validation.passwordconfirm === "" &&
        validation.gender === "" &&
        validation.cgv === ""
      ) {
        axios
          .post("/signup/", inputs)
          .then((res) => {
            //.log(res);

            if (res.data.message === "UserExist") {
              toast.error(t("SignupPageNotif1"), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else {
              toast.success(t("SignupPageNotif2"), {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              navigate("/email-confirmation");
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              toast.error(t("SignupPageNotif1"), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }

            toast.error(t("SignupPageNotif1"), {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
      }
    } else {
      toast.error(t("SignupPageNotif3"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const checkValidation = () => {
    var errors = validation;

    //first Name validation
    if (!inputs.firstname.trim()) {
      errors.firstname = t("SignupPageError1");
    } else {
      errors.firstname = "";
    }
    //last Name validation
    if (!inputs.lastname.trim()) {
      errors.lastname = t("SignupPageError2");
    } else {
      errors.lastname = "";
    }

    // email validation
    const emailCond =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/;
    if (!inputs.email.trim()) {
      errors.email = t("SignupPageError3");
    } else if (!inputs.email.match(emailCond)) {
      errors.email = t("SignupPageError4");
    } else {
      errors.email = "";
    }

    //password validation

    const password = inputs.password;
    if (!password) {
      errors.password = t("SignupPageError5");
    } else if (password.length < 6) {
      errors.password = t("SignupPageError6");
    } else if (password.length >= 20) {
      errors.password = t("SignupPageError7");
    } else {
      errors.password = "";
    }

    //matchPassword validation
    if (!inputs.passwordconfirm) {
      errors.passwordconfirm = t("SignupPageError8");
    } else if (inputs.passwordconfirm !== inputs.password) {
      errors.passwordconfirm = t("SignupPageError9");
    } else {
      errors.passwordconfirm = "";
    }

    //cgv validation
    if (inputs.cgv === false) {
      errors.cgv = t("SignupPageError10");
    } else {
      errors.cgv = "";
    }

    setValidation(errors);
    setIsSubmitted(false);
  };
  /*
  useEffect(() => {
    checkValidation();
  }, [inputs]); */

  const onLoad = () => {
    captchaRef.current.execute();
  };

  // useEffect(() => {
  //   if (token) //.log(`hCaptcha Token: ${token}`);
  // }, [token]);

  return (
    <>
      <div className="row d-flex align-items-center my-2 my-md-5">
        <div className="col-11 col-lg-8 pt-3 mx-auto text-center">
          <h3>{t("SignupPageMsg1")}</h3>
        </div>
        <div className="col-11 col-lg-8 py-4 px-lg-6 mx-auto">
          <div className="form__account">
            <div className="row d-flex flex-column align-items-center">
              <div className="col-12 col-md-6 d-flex flex-column mx-auto gap-2">
                {/* <a
                  className="btn btn-primary"
                  style={{ backgroundColor: "#002d6b" }}
                  href="https://api.veritatrust.com/v1/auth/facebook"
                >
                  {" "}
                  {t("SignupPageWithFacebook")}
                </a> */}

                <a
                  className="btn btn-primary"
                  style={{ backgroundColor: "#002d6b" }}
                  href="https://api.veritatrust.com/v1/auth/google"
                >
                  {" "}
                  {t("SignupPageWithGoogle")}
                </a>
                {/* <MetamaskButton
                  propsMsgButton={t("SignupPageWithMetamask")}
                ></MetamaskButton> */}

                <a href="#" className="btn btn-primary d-none">
                  Sign up with Apple
                </a>
              </div>
              <div className="col-12 col-md-6 g-0 mx-auto">
                <div className="orbymail text-center">
                  <div className="separator">{t("SignupPageUsingEmail")}</div>
                </div>
              </div>
              <form id="signup" onSubmit={handleSubmit}>
                <div className="col-12 col-md-6 mx-auto">
                  <div className="d-flex gap-3">
                    <p>{t("SignupPageGender")}</p>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        value="Mr."
                        id="civilite1"
                        checked={inputs.gender === "Mr."}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="civilite1">
                        {t("SignupPageMr")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        value="Mrs."
                        id="civilite2"
                        checked={inputs.gender === "Mrs."}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="civilite2">
                        {t("SignupPageMrs")}
                      </label>
                    </div>
                  </div>
                  <div className="account__name d-lg-flex gap-3">
                    <div className="mb-3 w-100">
                      <input
                        type="text"
                        className="form-control"
                        id="firstname"
                        name="firstname"
                        title="Firstname"
                        placeholder={t("SignupPageFirstName")}
                        value={inputs.firstname || ""}
                        onChange={handleChange}
                      />
                      {isSubmitted && validation.firstname && (
                        <p className="text-danger mt-1 mb-0">
                          {validation.firstname}
                        </p>
                      )}
                    </div>

                    <div className="mb-3 w-100">
                      <input
                        type="text"
                        className="form-control"
                        id="lastname"
                        name="lastname"
                        title="Lastname"
                        placeholder={t("SignupPageLastName")}
                        value={inputs.lastname || ""}
                        onChange={handleChange}
                      />
                      {isSubmitted && validation.lastname && (
                        <p className="text-danger mt-1 mb-0">
                          {validation.lastname}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      title="Email"
                      placeholder={t("SignupPageEmail")}
                      name="email"
                      value={inputs.email || ""}
                      onChange={handleChange}
                    />
                    {isSubmitted && validation.email && (
                      <p className="text-danger mt-1">{validation.email}</p>
                    )}
                  </div>
                  <div className="mb-3">
                    <div className="input-group">
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        title="Password"
                        placeholder={t("SignupPagePassword")}
                        value={inputs.password || ""}
                        onChange={handleChange}
                      />

                      <span className="input-group-text bg-success toggle-password text-white border-success">
                        <i className="flaticon-hide"></i>
                      </span>
                    </div>
                    {isSubmitted && validation.password && (
                      <p className="text-danger mt-1">{validation.password}</p>
                    )}
                  </div>
                  <div className="mb-3">
                    <div className="input-group">
                      <input
                        type="password"
                        className="form-control"
                        id="passwordconfirm"
                        name="passwordconfirm"
                        title="Password"
                        placeholder={t("SignupPageConfirmPassword")}
                        value={inputs.passwordconfirm || ""}
                        onChange={handleChange}
                      />
                      <span className="input-group-text bg-success toggle-password text-white border-success">
                        <i className="flaticon-hide"></i>
                      </span>
                    </div>
                    {isSubmitted && validation.passwordconfirm && (
                      <p className="text-danger mt-1">
                        {validation.passwordconfirm}
                      </p>
                    )}
                  </div>
                  <hr />
                  <p className="d-none">
                    <a href="#">Vous avez un parrain ?</a>
                  </p>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="cgv"
                      id="cgv"
                      value={inputs.cgv || ""}
                      onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="cgv">
                      {t("SignupPageMsg2")}
                    </label>
                    {isSubmitted && validation.cgv && (
                      <p className="text-danger mt-1">{validation.cgv}</p>
                    )}
                  </div>
                  <div className="d-none">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="add_newsletter"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="add_newsletter"
                    >
                      {t("SignupPageMsg3")}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-md-6 mx-auto">
                  <div className="d-none" id="error-message">
                    <div className="me-3">
                      <i className="flaticon-information"></i>
                    </div>
                    <div>Error message</div>
                  </div>
                  <div className="d-none" id="message-confirm">
                    <div className="me-3">
                      <i className="flaticon-check"></i>
                    </div>
                    <div>
                      Your account is created, Check your email for confirmation
                    </div>
                  </div>

                  <div className="form__footer">
                    <HCaptcha
                      sitekey="1be3a25e-95cb-441d-a951-f140b9e09428"
                      explicit={true}
                      onLoad={onLoad}
                      onVerify={setToken}
                      ref={captchaRef}
                    />
                    <div className="d-grid gap-2">
                      <button
                        type="submit"
                        className="btn-block btn btn-success text-white"
                        id="signinbutton"
                      >
                        {t("SignupPageCreateAccount")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default Create;
