import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setSharedValue } from "../../actions/types";
import NoPicture from "../../assets/no-picture.png";
import star1 from "../../assets/star-1.jpg";
import star2 from "../../assets/star-2.jpg";
import star3 from "../../assets/star-3.jpg";
import star4 from "../../assets/star-4.jpg";
import star5 from "../../assets/star-5.jpg";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function ProductDiv({ propsRM, setSharedValue }) {
  let { product_name } = useParams();
  const [searchResults, setSearchResults] = useState(null);
  const [product_id, setProduct_id] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [vtCatName, setVtCatName] = useState("");
  const [vtCatId, setVtCatId] = useState(null);
  const [categoryId, setCategoryId] = useState("");

  const axios = useAxios();

  const ratingName = ["Very bad", "Bad", "Good", "Very good", "Excellent"];
  const srcimages = [star1, star2, star3, star4, star5];
  let srcimg = star1;
  let srcvalue = "star" + parseInt(propsRM).toString();
  switch (srcvalue) {
    case "star1":
      srcimg = star1;
      break;
    case "star2":
      srcimg = star2;
      break;
    case "star3":
      srcimg = star3;
      break;
    case "star4":
      srcimg = star4;
      break;
    case "star5":
      srcimg = star5;
      break;
    default:
  }

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang);
    let fetchReviews = async () => {
      const response = await axios.get(
        `/products/getone/${parseInt(product_name.split("-")[0])}`
      );
      const resp = await axios.get(
        `/data/categories/admin/get-category-id/${response.data.category_id}`
      );
      if (window.localStorage.getItem("langPage") === "en") {
        setCategoryName(
          resp.data[0].concatenated_pathname.split(" > ").reverse().join(" > ")
        );
      } else {
        setCategoryName(
          resp.data[0][
            `concatenated_pathname_${window.localStorage.getItem("langPage")}`
          ]
            .split(" > ")
            .reverse()
            .join(" > ")
        );
      }
      setVtCatName(resp.data[0].vt_category);
      setVtCatId(resp.data[0].google_category_id);
      setCategoryId(
        resp.data[0].concatenated_id_path.split(" > ").reverse().join(" > ")
      );
    };
    fetchReviews();
  }, [, lang]);

  useEffect(() => {
    axios
      .get(`/products/getone/${parseInt(product_name.split("-")[0])}`)
      .then((response) => {
        setSearchResults(response.data);

        setProduct_id(parseInt(product_name.split("-")[0]));
        setSharedValue(response.data.ReviewsNumber);

        //.log(response.data);
      })
      .catch((error) => {
        //.error(error);
      });
  }, []);

  return (
    <>
      <section className="profil-header">
        <div className="container">
          <div className="row mt-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0 mb-md-3">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="flaticon-home"></i>{" "}
                    {t("ReviewMerchantPageWriteHome")}
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/c">{t("ReviewMerchantPageCategory")}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <span className="me-1">
                    <Link
                      to={
                        "/" +
                        window.localStorage.getItem("langPage") +
                        "/c/" +
                        vtCatId +
                        "-" +
                        vtCatName
                      }
                    >
                      {vtCatName}
                    </Link>
                    <span className="ms-1">{">"}</span>
                  </span>

                  {categoryName.split(">").map((cat, index) => (
                    <span className="me-1">
                      <Link
                        to={
                          "/" +
                          window.localStorage.getItem("langPage") +
                          "/c/" +
                          categoryId.split(">")[index] +
                          "-" +
                          cat
                        }
                        key={index}
                      >
                        {cat}
                      </Link>
                      {index < categoryName.split(">").length - 1 && (
                        <span className="ms-1">{">"}</span>
                      )}
                    </span>
                  ))}
                </li>
              </ol>
            </nav>
          </div>
          <div
            className="row d-flex align-items-center mb-3 aos animated aos-init aos-animate"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <div className="col-lg-8 pt-2 pb-lg-5 pb-1 d-grid info-mark">
              <div className="logo my-1">
                {searchResults && (
                  <img
                    src={searchResults.aw_image_url || NoPicture}
                    alt={searchResults.product_name}
                    width="200"
                    height="200"
                  />
                )}
              </div>
              <div className="brand">
                {searchResults && (
                  <>
                    <h1>{searchResults.product_name.replace(/-/g, " ")}</h1>

                    <small className="text-muted">
                      <strong>{searchResults.ReviewsNumber}</strong>{" "}
                      {t("ReviewMerchantPageReviews")}•{" "}
                      {searchResults.ReviewMean > 0
                        ? ratingName[parseInt(searchResults.ReviewMean) - 1]
                        : ""}
                    </small>
                  </>
                )}
                <div className="form__header__note">
                  <div className="form__star mb-3 d-flex justify-content-start mx-auto mx-md-0">
                    {searchResults && (
                      <img
                        alt="Noté sur 5 étoiles"
                        src={
                          parseInt(searchResults.ReviewMean) >= 1
                            ? srcimages[parseInt(searchResults.ReviewMean) - 1]
                            : srcimages[0]
                        }
                        width="120"
                        height="70"
                      />
                    )}

                    {searchResults && (
                      <p className="note lead my-auto ms-3 text-success">
                        {searchResults.ReviewsNumber > 0
                          ? searchResults.ReviewMean
                          : ""}
                      </p>
                    )}
                  </div>
                  <Link
                    to="/ask_question"
                    className="btn btn-primary rounded-3 shadow m-1"
                  >
                    {t("ReviewProductPageAskQuestion")}
                  </Link>
                  <Link
                    to={
                      "/" + t("lang") + "/listings/write-review/" + product_name
                    }
                    className="btn btn-success rounded-3 shadow m-1"
                  >
                    {t("ReviewMerchantPageWriteReview")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
const mapStateToProps = (state) => ({
  sharedValue: state.auth.sharedValue,
});

const mapDispatchToProps = {
  setSharedValue,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDiv);
