import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import "../../styles/bootstrap.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function CreateProductInput({ handleSelectProduct, resetInput }) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const axios = useAxios();
  const location = useLocation();
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);
  useEffect(() => {
    if (resetInput) {
      setSearchTerm("");
    }
  }, [resetInput]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);

    // Send a request to the server to search for categories matching the input value
    if (inputValue.length >= 1) {
      axios
        .get(`/search-productlist/?q=${inputValue}`)
        .then((response) => {
          setSearchResults(response.data);
          setShow(true);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setSearchResults([]);
      setShow(false);
    }
  };

  const handleSelectProductInput = (suggestion) => {
    setSearchResults([]);
    setSearchTerm(suggestion.product_name);
    setShow(false);
    handleSelectProduct(suggestion);
  };

  function handleAddNewProduct() {
    axios
      .post(`/products/`, {
        product_name: searchTerm,
      })
      .then((response) => {
        if (searchTerm.length >= 1) {
          axios
            .get(`/search-productlist/?q=${searchTerm}`)
            .then((response) => {
              setSearchResults(response.data);
              setShow(true);
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          setSearchResults([]);
          setShow(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <div className="row mb-md-3">
        {/* <label
          htmlFor="product"
          className={
            location.pathname === `/${t("lang")}/add-review-create-for-beta`
              ? "col-md-3 col-form-label"
              : "col-form-label"
          }
        >
          {t("AddReviewForBetaTesterProductNamePlaceholder")}
          <sup>*</sup>
        </label> */}
        <div
          className={
            location.pathname === `/${t("lang")}/add-review-create-for-beta`
              ? "col-md-9 dropdown"
              : "dropdown"
          }
        >
          <input
            type="text"
            className="form-control"
            id="product"
            required
            name="product"
            onChange={handleInputChange}
            value={searchTerm}
            autoComplete="off"
            placeholder={t("AddReviewForBetaTesterProductNamePlaceholder")}
            onBlur={() => {
              setTimeout(() => setShow(false), 200);
            }}
            onFocus={() => {
              setShow(true);
            }}
          />

          {searchTerm.length >= 2 && (
            <ul className={show ? "dropdown-menu show" : "dropdown-menu"}>
              <>
                <li>
                  <p className="dropdown-header">Product not found</p>
                </li>
                <li>
                  <button
                    className="dropdown-item active"
                    type="button"
                    onClick={handleAddNewProduct}
                  >
                    Add a new product
                  </button>
                </li>
              </>
              {searchResults.length > 0 &&
                searchResults.map((suggestion, index) => (
                  <li key={index}>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleSelectProductInput(suggestion)}
                    >
                      {suggestion.product_name}
                    </button>
                  </li>
                ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateProductInput;
